<template>
    <div class="container">
        <div class="row">
            <img class="img-fluid" id="mpb-logo" src="../assets/logo.png" alt="logo mobile pare brise">
        </div>
        <div id="login-input">
            <div class="row">
                <div class="">
                    <label for="email" class="form-label">Email : </label><br>
                    <input type="email" v-model="email" class="form-control button" id="email" placeholder="Votre email">
                </div>
            </div>
            <div class="row d-flex justify-content-center">
                <div class="mt-2">
                    <input @click="check_user" class="button green" type="button" value="Se connecter">
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Login',
    data: () => {
        return {
            email: '',
            register_user: [],
            id: null
        }
    },
    methods: {
        check_user(){
                axios.get(`${process.env.VUE_APP_BASEURL}/users/` + this.email).then(response => {

                    this.register_user = response.data;

                    if ( this.register_user.length == 0 ){
                    document.getElementById('email').style.border = "1px solid red";
                    document.getElementById('email').placeholder = "email non reconnue";
                    return false;
                    } else {
                        this.email = '';
                        this.link = '';
                        this.id = this.register_user['ID'];
                        if (this.id != null){
                            this.$router.push({ path:"/home/" + this.id}); 
                        }
                    }   
                }).catch(error => console.log(error));
        }
    }
}
// document.getElementById('wp-login').addEventListener('click', (e) => {

// });

</script>

<style>

body{
    background: url("../assets/rain.jpg");
    height: 100vh;
    width: 100vw;
    padding: 0;
    margin: 0;
}

#mpb-logo{
    width: 100vw;
}

#login-input{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100vh;
    width: 100vw;
    position: absolute;
    top: 0;
    left: 0;
}

#login-input label {
    color: white;
    font-size: 1.2em;
}

.button {
    width: 80vw;
    height: 40px;
    padding: 0;
    border: none;
    text-align: center;
}

.green {
    background-color: rgba(66, 255, 59, 0.712);
}


</style>
