<template>
  <div class="home">
    <div id="home-container">
        <router-link :to="`/${this.id}/form`"><button class="button green">Nouveau client</button></router-link>
        <router-link :to="`/${this.id}/history`"><button class="button green">Historique</button></router-link>
        <router-link to="/"><button class="button green">Déconnexion</button></router-link>
      </div>
  </div>
  
</template>

<script>
export default {
    name: 'Home',
    data: () => {
        return {
            id: null,
        }
    },
    created(){
        this.id = this.$route.params.id
    }
}
</script>

<style scoped>
#home-container{
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

#home-container .button{
    border: 1px solid black;
    font-size: 1.2em;
    border: 1px solid white;
}

.button {
    width: 80vw;
    height: 40px;
    padding: 0;
    margin: 10px 0 0 0;
    border: none;
    text-align: center;
}

.green {
    background-color: rgba(66, 255, 59, 0.712);
}
</style>