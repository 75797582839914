<template>
     
        <div id="form-container">
          <div id="return-button-container">
            <router-link :to="`/home/${this.id}`"><button class="btn btn-sm btn-secondary">Retour</button></router-link>
          </div>
            <form method="POST">
                <input type="hidden" name="id" :value="id" id="id">

                <div class="card my-2">
                    <div class="card-header"><label for="name"></label> Nom et Prénom : </div>
                    <div class="card-body">
                        <input v-model="name" type="text" placeholder="Nom et prénom client" name="name" id="name" required>
                    </div>
                </div>

                <div class="card my-2">
                    <div class="card-header">Carte verte : </div>
                    <div class="card-body">
                        <input @change="inputFileComplete" type="file" accept="image/*" capture="camera" name="green_card" id="green_card" class="input-file" required>
                        <label class="green_card" for="green_card">Choisissez un fichier ... <span v-if="v_green_card"><i class="fas fa-check"></i></span><span v-else><i class="fas fa-download"></i></span></label> 
                    </div>
                </div>

                <div class="card my-2">
                    <div class="card-header">Carte grise : </div>
                    <div class="card-body">
                        <input @change="inputFileComplete" type="file" accept="image/*" capture="camera" name="gray_card" id="gray_card" class="input-file" required>
                        <label class="gray_card" for="gray_card">Choisissez un fichier ... <span v-if="v_gray_card"><i class="fas fa-check"></i></span><span v-else><i class="fas fa-download"></i></span></label> 
                    </div>
                </div>

                <div class="card my-2">
                    <div class="card-header">Photo impact : </div>
                    <div class="card-body">
                        <input @change="inputFileComplete" type="file" accept="image/*" capture="camera" name="impact" id="impact" class="input-file">
                        <label class="impact" for="impact">Choisissez un fichier ... <span v-if="v_impact"><i class="fas fa-check"></i></span><span v-else><i class="fas fa-download"></i></span></label>
                    </div>
                </div>

                <div class="card my-2">
                    <div class="card-header"><label for="phone"></label> N° Téléphone : </div>
                    <div class="card-body">
                        <input v-model="phone" type="tel" placeholder="Numéro client" name="phone" id="phone" maxlength="10" required>
                    </div>
                </div>

                <div class="card my-2">
                    <div class="card-header"><label for="department"></label> Département : </div>
                    <div class="card-body">
                        <input v-model="department" type="number" placeholder="Numéro département" name="department" id="department" maxlength="3" autocomplete="off" required>
                    </div>
                </div>

                <div class="card my-2">
                    <div class="card-header"><label for="comment"></label> Commentaire : </div>
                    <div class="card-body">
                        <textarea v-model="comment" placeholder="Votre commentaire" name="comment" id="comment" rows="3"></textarea>
                    </div>
                </div>

                <div v-show="v_show_1" class="alert alert-success mt-3"> {{ response['status'] }}</div>
                <div v-show="v_show_2" class="alert alert-danger"> {{ response['status'] }}</div>
                <div v-show="uncomplete" class="alert alert-warning">Il manque des informations avant de pouvoir créer le parrain</div>
                <div v-show="trySendTwice" class="alert alert-warning">Attention vous essayez d'envoyer deux fois le même parrainage</div>

                 <input @click="submittedForm" type="button" id="form-submit-input" class="btn btn-success" value="Envoyer">
            </form>
        </div>
</template>

<script>

const { VUE_APP_MODE } = process.env;

export default {
    name: 'Form',
    data: () => {
        return {
            id: null,
            name: '',
            phone: '',
            department: '',
            comment: '',
            response: {
                code: 0,
                status: '',
            },
            v_show_1: false,
            v_show_2: false,
            v_impact: false,
            v_green_card: false,
            v_gray_card: false,
            uncomplete: false,
            reload: false,
            trySendTwice: false,
        }
    },
    created(){
        this.id = this.$route.params.id
    },
    methods: {
        inputFileComplete (e) {
            switch (e.target.id) {
                    case 'impact':
                        if (e.target.files[0] != null){
                            this.v_impact = true;
                        } else {
                            this.v_impact = false;
                        }
                        break
                    case 'gray_card':
                        if (e.target.files[0] != null){
                            this.v_gray_card = true;
                        } else {
                            this.v_gray_card = false;
                        }
                        break
                    case 'green_card':
                        if (e.target.files[0] != null){
                            this.v_green_card = true;
                        } else {
                            this.v_green_card = false;
                        }
                        break
                }
        },
        submittedForm (e) {

            if (this.name != '' && this.phone != '' && this.department != '' && this.v_green_card && this.v_gray_card){

                if(this.reload == false){

                    this.uncomplete = false;
                    let formData = new FormData();
                    let input_files = document.querySelectorAll('input[type=file]');
                    input_files.forEach((input) => {
                        switch (input.id) {
                            case 'impact':
                                formData.append('impact', input.files[0]);
                                break
                            case 'gray_card':
                                formData.append('gray_card', input.files[0]);
                                break
                            case 'green_card':
                                formData.append('green_card', input.files[0]);
                                break
                        }
                    });
                    formData.append('phone', this.phone);
                    formData.append('id', this.id);
                    formData.append('name', this.name);
                    formData.append('department', this.department);
                    formData.append('comment', this.comment);

                    axios.post(`${process.env.VUE_APP_BASEURL}/prospects/create`, formData, {
                        'Content-Type' : 'multipart/form-data',
                    }).then((response) => {
                        this.response = response.data;
                        setTimeout(() => {
                            this.showAlertMessage();
                        }, 500)
                        setTimeout(() => {
                            this.$router.push(`/home/${this.id}`)
                        }, 5000)
                    }).catch(error => console.log(error));

                    

                    this.reload = true;

                } else {
                    this.trySendTwice = true;
                }
                
            } else {
                this.uncomplete = true;
            }
            
        },

        showAlertMessage(){
            if (this.response.code === 200){
                this.v_show_1 = true;
                this.v_show_2 = false;
            } else if (this.response.code === 500){
                this.v_show_1 = false;
                this.v_show_2 = true;
            }
        }
    },

    goTo(route) {
          VUE_APP_MODE === 'web' ? this.$router.push(route) : this.$navigator.navigate(route);
    }
}

</script>

<style scoped>

#return-button-container{
    display: flex;
    justify-content: flex-start;
}

#form-container{
    border-radius: 10px;
    margin: 10px;
}

#phone, #department, #name, #comment {
    width: 100%;
}

.card {
    border: 2px solid rgba(120, 226, 87, 0.808);
}

.card-header{
    background-color: rgba(120, 226, 87, 0.808);
    max-height: 30px;
    padding: 0;
    font-weight: 500;
}

.card-body{
    padding: 10px;
}

.input-groupe{
    background-color: rgba(120, 226, 87, 0.808);
    padding: 5px;
    margin-bottom: 10px;
    border-radius: 15px;
    width: calc(100% - 40px);
    border: 1px solid black;
    box-shadow: 2px 2px 5px gray;
}

.input-file{
    width: 0.1px;
	height: 0.1px;
	opacity: 0;
	overflow: hidden;
	position: absolute;
	z-index: -1;
}

.input-file + label {
    font-size: 1.25em;
    color: black;
    display: inline-block;
    border: 2px solid black;
    border-radius: 5px;
    padding: 3px;
}

.input-file:focus + label,
.input-file + label:hover {
    background-color: rgba(99, 251, 53, 0.815);
}

</style>
