<template>
    <div id="history-container">
        <div id="return-button-container">
          <router-link :to="`/home/${this.id}`"><button class="btn btn-sm btn-secondary mb-3">Retour</button></router-link>
        </div>

        <table class="table table-striped">
        <thead>
            <tr>
            <th scope="col">#</th>
            <th scope="col">Date</th>
            <th scope="col">Département</th>
            <th scope="col">Statut</th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="client in clients.body_response" :key="client.ID">
            <th scope="row">{{ client.ID }}</th>
            <td>{{ client.post_date }}</td>
            <td>{{ client.metadata.department[0] }}</td>
            <td class="status">{{ client.post_status }}</td>
            </tr>
        </tbody>
        </table>

      </div>
</template>

<script>
export default {
    name: 'History',
    data: () => {
        return {
            id: null,
            clients: [],
        }
    },
    created(){
        this.id = this.$route.params.id
    },
    methods : {
        get_history(){
            axios.get(`${process.env.VUE_APP_BASEURL}/commerciaux/` + this.id).then(response => this.clients = response.data).catch(error => console.log(error));

            setTimeout(function(){
                    var status = document.querySelectorAll('.status');
                console.log(status);
                status.forEach(element => {
                    console.log(element);
                    if (element.textContent === 'Validé'){
                        element.classList.add('history-green');
                    } else if (element.textContent === 'En cours'){
                        element.classList.add('blue');
                    } else {
                        element.classList.add('red');
                    }
                });
            }, 500)
            
        },
        
    },
    beforeMount(){
        this.get_history();
    }
}
</script>

<style scoped>
    #history-container{
        background-color: rgba(255, 255, 255, 0.89);
        border-radius: 10px;
        width: 100vw;
    }

    #return-button-container{
    display: flex;
    justify-content: flex-start;
    padding: 5px;
    }

    table{
        width: 100vw;
    }

    table .red{
        color: red!important;
    }
    table .history-green{
        color: green!important;
    }
    table .blue{
        color: blue!important;
    }

</style>
